
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import RegistrationForm from '@/components/organisms/RegistrationForm.vue';
import RegisterUserForm from '@/domain/models/RegisterUserForm';
import BackSteps from '@/components/molecules/BackSteps.vue';
import { NavigationFailure, useRoute, useRouter } from 'vue-router';
import isEmpty from '@/domain/services/isEmptyChecker';
import { useStore } from 'vuex';
import PlanCardModel from '@/domain/models/PlanCard';
import PromoCode from '@/domain/models/PromoCode';
import Subscription from '@/domain/models/Subscription';
import SelectedSubscription from '@/components/molecules/SelectedSubscription.vue';
import Discount from '@/domain/models/Discount';
import { useLoading } from 'vue-loading-overlay';
import StepDone from '@/components/atoms/StepDone.vue';
import LoginUserForm from '@/domain/models/LoginUserForm';
import ActivatePlan from '@/domain/models/ActivatePlan';
import NavigationRedirector from '@/domain/services/NavigationRedirector';
import Button from '@/components/atoms/button.vue';
import Modal from '@/components/atoms/Modal.vue';
import { Steps } from '@/domain/services/types';
import useAmplitude from '@//composables/useAmplitude';
import PaymentForm from '../components/organisms/PaymentForm.vue';
import planCard from '../components/organisms/PlanCardLegacy.vue';

export default defineComponent({
  name: 'HomeView',
  computed: {
    planCard() {
      return planCard;
    },
  },
  components: {
    RegistrationForm,
    BackSteps,
    SelectedSubscription,
    PaymentForm,
    StepDone,
    Button,
    Modal,
  },
  async setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const loading = useLoading({
      backgroundColor: 'black',
      color: 'white',
    });
    const generalError = reactive({
      show: false,
      title: null as string | null,
      message: null as string | null,
    });
    const discountError = ref(false);
    const discountCode = ref('');
    const discountAmount = ref(0);
    const paymentError = ref('');
    const couponUsed = ref(null);
    const username = computed(() => store.getters.user?.username || '');

    onMounted(() => {
      const m = {
        _globals: {
          appId: process.env.VUE_APP_KOMMUNICATE_APP_ID,
          popupWidget: true,
          automaticChatOpenOnNavigation: true,
        },
      };
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.kommunicate.io/v2/kommunicate.app';
      const h = document.getElementsByTagName('head')[0];
      h.appendChild(s);
      useAmplitude()
        ?.logEvent('Onboarding_sign_up_screen');
      (window as any).kommunicate = m;
    });
    onUnmounted(() => {
      (window as any).Kommunicate?.logout();
    });

    const selectedIndex = ref(0);
    const appliedDiscount = ref(false);
    const step = ref(Steps.Registration);
    const selectedPlan = ref();
    const selectedSubscription = ref();
    const discount = ref();
    const discountName = computed(() => store.getters.coupon);
    const planCards = ref([...store.getters.plans]);

    async function getAllPlans() {
      const loader = loading.show();
      await store.dispatch('getPlans')
        .then((res) => {
          planCards.value.unshift(...res);
        });

      const isProductInPlans = planCards.value[0].subscriptions.find(
        (subscription: Subscription) => subscription.id === route.query.subscriptionId,
      );

      if (isEmpty(isProductInPlans)) {
        await store.dispatch('getPlanByProductId', route.query.subscriptionId);
      }

      loader.hide();
    }
    await getAllPlans();

    const applyDiscount = (code: string) => {
      store.dispatch('checkPromoCode', new PromoCode(
        code,
        selectedSubscription.value.paymentProductId,
      ))
        .then((res: Discount) => {
          discountAmount.value = res.value;
          discountError.value = false;
          appliedDiscount.value = true;
          discount.value = res;
          couponUsed.value = discount.value.label;
        })
        .catch((err) => {
          discountError.value = true;
        });
    };

    const postLogin = () => {
      if (store.getters.coupon) {
        applyDiscount(store.getters.coupon);
        discountCode.value = store.getters.coupon;
      }
    };

    const createUser = (form: RegisterUserForm) => {
      if (form.name !== '' && form.email !== '' && form.password !== '') {
        const loader = loading.show();
        store.dispatch('register', form)
          .then(() => {
            store.dispatch('login', new LoginUserForm(form.email, form.password))
              .then(() => {
                store.dispatch('userBasicInfo')
                  .then(() => {
                    selectedIndex.value += 1;
                    step.value = Steps.Payment;
                    const query = { ...route.query };
                    query.data = 'pay';
                    router.push({ query });
                    const userProperties = {
                      email: form.email,
                      name: form.name,
                    };
                    useAmplitude()
                      ?.setUserProperties(userProperties);
                    postLogin();
                  });
              });
          })
          .catch((data) => {
            generalError.show = true;
            generalError.title = 'Ya estabas en INDYA';
            generalError.message = data.message;
          })
          .finally(() => {
            loader.hide();
          });
      }
    };

    function confirmSecurePayment(stripe: any, clientSecret: string, loader: any) {
      stripe.confirmCardPayment(clientSecret)
        .then(() => {
          store.dispatch('userBasicInfo')
            .then(() => {
              step.value = Steps.Done;
              selectedIndex.value = 2;
              loader.hide();
              const query = { ...route.query };
              query.data = 'complete';
              router.push({ query });
            });
        });
    }

    function confirmPayment(loader: any) {
      store.dispatch('userBasicInfo')
        .then(() => {
          step.value = Steps.Done;
          selectedIndex.value = 2;
          loader.hide();
          const query = { ...route.query };
          query.data = 'complete';
          router.push({ query });
        });
    }

    const pay = (card: any, stripe: any) => {
      const loader = loading.show();
      store.dispatch('createCard', card.token.id)
        .then((cardId: string) => {
          const data = new ActivatePlan(
            cardId,
            selectedSubscription.value.paymentProductId,
            discount.value?.promoId,
          );
          store.dispatch('activatePlan', data)
            .then((activatePlan) => {
              if (activatePlan.data.data.subscription.latest_invoice) {
                confirmSecurePayment(
                  stripe,
                  activatePlan.data.data.subscription.latest_invoice.payment_intent.client_secret,
                  loader,
                );
              }
              if (!activatePlan.data.data.subscription.latest_invoice) {
                confirmPayment(loader);
              }
            }).catch((res) => {
              paymentError.value = res.response.data.data.message;
              loader.hide();
            });
        })
        .catch((res) => {
          paymentError.value = res.response.data.data.message;
          loader.hide();
        })
        .finally(() => {
          useAmplitude()
            ?.logEventData('subscription_purchase', { data: { custom_vals: selectedSubscription.value.name } });
        });
      (window as any).dataLayer.push({
        event: 'subscription_purchase',
        data: {
          custom_vals: selectedSubscription.value.name,
        },
      });
    };

    const setData = (data: PlanCardModel[]) => {
      selectedPlan.value = data
        .find((planCard: PlanCardModel) => planCard.id.toString() === route.query.planId);

      selectedSubscription.value = selectedPlan.value?.subscriptions
        .find((subs: Subscription) => subs.id === route.query.subscriptionId);

      if ((selectedSubscription.value && selectedPlan) && store.getters.loggedUser) {
        if (!NavigationRedirector.userHasPayedSubscription(store.getters.user)) {
          router.push({ name: 'profile' });
        }
        step.value = Steps.Payment;
        selectedIndex.value = 1;
        const query = { ...route.query };
        query.data = 'pay';
        router.push({ query });
        postLogin();
      }
    };

    setData(planCards.value);
    return {
      step,
      Steps,
      selectedPlan,
      selectedSubscription,
      elements: ['Registro', 'Datos de pago', 'Confirmación'],
      appliedDiscount,
      selectedIndex,
      discount,
      generalError,
      discountAmount,
      discountError,
      discountCode,
      paymentError,
      couponUsed,
      discountName,
      username,

      createUser,
      applyDiscount,
      pay,
    };
  },
});
